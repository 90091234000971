
//Helpers
@import 'helpers/mixins';
@import 'helpers/variables';
@import 'helpers/animations';

//vendor
@import "../node_modules/flint-gs/stylesheets/_flint";
@include _(foundation);

//base
@import 'base/reset';
@import 'base/buttons.scss';
@import 'base/typography.scss';

//Global Styles
@import 'base/global-styles';
@import 'base/syntax';

//Components
@import 'includes/home-top-banner';
@import 'components/top-banner';
@import 'components/post-list';
@import 'components/portfolio-list';
@import 'components/contact-form';
@import 'components/sidebar';


//includes
@import 'includes/header';
@import 'includes/footer';

//@import 'components/sidebar.scss';


//Pages
@import 'pages/home';
@import 'pages/posts';
// @import 'pages/careers';
// @import 'pages/about';


//Temp Styles
body {
    background: $backgroundColor;
}

header,
footer {
    a {
        color: black;
    }
}

.full-width-panel {
    background: #E0E0E0;
    //@include _(container);
}

.columns div {
    background: green;
    margin-bottom: 20px;
    padding: 20px;
    @include _(4 6 8 4);

    @include _(for desktop) {
        background: #CBF0F0;
    }

    @include _(for laptop) {
        background: #E0E0E0;
    }

    @include _(for tablet) {
        background: #E9918B;
    }

    @include _(for mobile) {
        background: #CAEFA4;
    }
}

.columns-3 div {
    @include _(5.333 4 4 4);
    background: #E0E0E0;
    padding: 20px;
    margin-bottom: 25px;
}

.column-16 div {
    background: red;
    @include _(1);
    height: 50px;
}
