@-webkit-keyframes a {
  to {
    -webkit-transform: rotate(1turn); } }

@keyframes a {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn); } }

@-webkit-keyframes b {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes b {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

/* line 515, /Users/jonathan/Sites/hellojony/node_modules/flint-gs/stylesheets/flint/mixins/lib/_main.scss */
*, *:before, *:after {
  box-sizing: border-box; }

/*****************************************************************************/
/*
/* Common
/*
/*****************************************************************************/
/* Global Reset */
/* line 8, /Users/jonathan/Sites/hellojony/_scss/base/_reset.scss */
* {
  margin: 0;
  padding: 0; }

/* line 13, /Users/jonathan/Sites/hellojony/_scss/base/_reset.scss */
html, body {
  height: 100%; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
/* line 20, /Users/jonathan/Sites/hellojony/_scss/base/_reset.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
/* line 41, /Users/jonathan/Sites/hellojony/_scss/base/_reset.scss */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

/* line 45, /Users/jonathan/Sites/hellojony/_scss/base/_reset.scss */
body {
  line-height: 1.5; }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
.btn, #c-submit-button {
  display: inline-block;
  padding: 13px 15px;
  color: #fff !important;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 160px;
  text-align: center;
  font-size: 14px;
  border-radius: 3px;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Avenir Next";
  transition: all .5s ease; }
  @media (min-width: 30.0625em) {
    /* line 2, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
    .btn, #c-submit-button {
      min-width: 180px; } }
  @media (min-width: 87.5625em) {
    /* line 2, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
    .btn, #c-submit-button {
      min-width: 200px; } }
  /* line 30, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
  .btn:hover, #c-submit-button:hover {
    background: #ff713f;
    box-shadow: none;
    color: #fff; }

/* line 37, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
.btn--dark, #c-submit-button {
  background: #464646; }
  /* line 40, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
  .btn--dark:hover, #c-submit-button:hover {
    background: #2d2d2d; }

/* line 45, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
.btn--accent {
  background: #FF9772; }
  /* line 48, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
  .btn--accent:hover {
    background: #ff713f; }

/* line 53, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
.btn--cta {
  background: #2DF8EB; }
  /* line 56, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
  .btn--cta:hover {
    background: #08eadc; }

/* line 61, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
.btn--ghost {
  background: transparent;
  border: 1px solid #FF9772;
  color: #FF9772; }
  /* line 66, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
  .btn--ghost:hover {
    background: #FF9772;
    color: #fff; }

@media (max-width: 50em) {
  /* line 72, /Users/jonathan/Sites/hellojony/_scss/base/_buttons.scss */
  .btn-wrapper {
    text-align: center; } }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
body {
  color: #3B3837;
  font-family: 'Avenir Next',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 9, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
h1,
h2,
h3,
h4,
p,
blockquote,
li,
a,
span,
.wpad_form_group label {
  font-family: sans-serif; }

/* line 22, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
h1, h2, h3, h4, h5 {
  font-weight: normal; }

/* line 26, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
h1 {
  font-size: 27px;
  line-height: 1.2;
  margin-bottom: 15px;
  font-family: "Avenir Next";
  color: #fff; }
  @media (min-width: 80.0625em) {
    /* line 26, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    h1 {
      font-size: 48px;
      line-height: 1.2;
      margin-bottom: 5px; } }
  @media (min-width: 30.0625em) and (max-width: 80em) {
    /* line 26, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    h1 {
      font-size: 36px; } }

/* line 43, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
h2,
.section__heading {
  font-size: 36px;
  line-height: 1.3;
  font-family: "avenirnext-medium";
  margin-bottom: 100px;
  color: #646464;
  text-transform: uppercase; }
  /* line 52, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
  h2--content,
  .section__heading--content {
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 1.3;
    font-family: "avenirnext-medium";
    color: #646464; }
    @media (min-width: 50.0625em) {
      /* line 52, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
      h2--content,
      .section__heading--content {
        font-size: 36px; } }

/* line 65, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
h3 {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 28px;
  font-family: "Avenir Next"; }
  @media (min-width: 50.0625em) {
    /* line 65, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    h3 {
      font-size: 28px; } }

/* line 76, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
h4 {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 15px;
  color: #3B3837; }
  @media (min-width: 50.0625em) {
    /* line 76, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    h4 {
      font-size: 22px; } }

/* line 87, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
p, label {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.5;
  color: #3B3837;
  font-family: "Avenir Next"; }
  @media (min-width: 50.0625em) {
    /* line 87, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    p, label {
      font-size: 18px; } }

/* line 99, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
strong {
  font-family: "avenirnext-demibold";
  color: #686564; }

/* line 104, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
.section__text {
  font-family: "Avenir Next";
  font-size: calc(0.18519vw + 15.40741px); }
  @media (max-width: 320px) {
    /* line 104, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    .section__text {
      font-size: 16px; } }
  @media (min-width: 1400px) {
    /* line 104, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    .section__text {
      font-size: 18px; } }

/* line 109, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
.top-menu a {
  font-family: "Avenir Next";
  color: #7d7d7d;
  font-size: 22px;
  text-transform: uppercase; }
  @media (min-width: 50.0625em) {
    /* line 109, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    .top-menu a {
      font-size: 14px; }
      /* line 119, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
      .top-menu a:hover {
        color: #FF9772; } }

/* line 127, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
a {
  font-size: 12px;
  font-family: 'montserratlight'; }
  @media (min-width: 50.0625em) {
    /* line 127, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    a {
      font-size: 16px; } }
  @media (min-width: 30.0625em) and (max-width: 80em) {
    /* line 127, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    a {
      font-size: 14px; } }

/* line 140, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
blockquote, blockquote p {
  font-size: 15px;
  color: #000;
  line-height: 1.3; }
  @media (min-width: 50.0625em) {
    /* line 140, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    blockquote, blockquote p {
      font-size: 30px; } }
  @media (min-width: 30.0625em) and (max-width: 80em) {
    /* line 140, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    blockquote, blockquote p {
      font-size: 24px; } }

/* line 156, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
::-webkit-input-placeholder {
  font-family: 'montserratlight';
  font-size: 12px; }
  @media (min-width: 50.0625em) {
    /* line 156, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    ::-webkit-input-placeholder {
      font-size: 16px; } }

/* line 166, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'montserratlight';
  font-size: 12px;
  color: #727378; }
  @media (min-width: 50.0625em) {
    /* line 166, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    :-moz-placeholder {
      font-size: 16px; } }

/* line 176, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'montserratlight';
  font-size: 12px;
  color: #727378; }
  @media (min-width: 50.0625em) {
    /* line 176, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    ::-moz-placeholder {
      font-size: 16px; } }

/* line 186, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
:-ms-input-placeholder {
  font-family: 'montserratlight';
  font-size: 12px;
  color: #727378; }
  @media (min-width: 50.0625em) {
    /* line 186, /Users/jonathan/Sites/hellojony/_scss/base/_typography.scss */
    :-ms-input-placeholder {
      font-size: 16px; } }

/* line 1, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.out-wrapper {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  zoom: 1;
  float: none; }

/* line 10, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.content-wrapper {
  float: left;
  width: 95.83333%;
  margin-right: 2.08333%;
  margin-left: 2.08333%;
  overflow: hidden; }
  @media (min-width: 80.0625em) {
    /* line 10, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .content-wrapper {
      width: 98.57143%;
      margin-right: 0.71429%;
      margin-left: 0.71429%; } }
  @media (min-width: 50.0625em) and (max-width: 80em) {
    /* line 10, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .content-wrapper {
      width: 98.4375%;
      margin-right: 0.78125%;
      margin-left: 0.78125%; } }
  @media (min-width: 30.0625em) and (max-width: 50em) {
    /* line 10, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .content-wrapper {
      width: 97.5%;
      margin-right: 1.25%;
      margin-left: 1.25%; } }

/* line 15, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.wrapper {
  overflow: hidden;
  zoom: 1;
  float: none;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  zoom: 1;
  float: none;
  max-width: 87.5em;
  margin-right: auto;
  margin-left: auto; }
  /* line 18, /Users/jonathan/Sites/hellojony/node_modules/flint-gs/stylesheets/flint/mixins/lib/_clearfix.scss */
  .wrapper:before, .wrapper:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  /* line 25, /Users/jonathan/Sites/hellojony/node_modules/flint-gs/stylesheets/flint/mixins/lib/_clearfix.scss */
  .wrapper:after {
    clear: both; }
  @media (min-width: 50.0625em) {
    /* line 15, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .wrapper {
      padding-left: 60px;
      padding-right: 60px; } }
  @media (min-width: 80.0625em) {
    /* line 15, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .wrapper {
      padding-left: 70px;
      padding-right: 70px; } }

/* line 39, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.accentColor {
  color: #FF9772; }

/* line 43, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.mrg-side-auto {
  margin-left: auto;
  margin-right: auto;
  display: block; }

/* line 49, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.anchor {
  display: block;
  height: 70px;
  /* this is the height of your header */
  margin-top: -70px;
  /* this is again negative value of the height of your header */
  visibility: hidden; }

/* line 56, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.svg-fix {
  position: relative;
  display: inline-block; }
  /* line 60, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
  .svg-fix:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

/* line 72, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.section {
  margin-bottom: 75px; }
  @media (min-width: 30.0625em) {
    /* line 72, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .section {
      margin-bottom: 125px; } }
  @media (min-width: 50.0625em) {
    /* line 72, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .section {
      margin-bottom: 200px; } }
  /* line 84, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
  .section__heading--white {
    color: #d0d0d0; }
  /* line 88, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
  .section__right, .section__left {
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%; }
    @media (min-width: 80.0625em) {
      /* line 88, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
      .section__right, .section__left {
        width: 48.57143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 88, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
      .section__right, .section__left {
        width: 48.4375%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 88, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
      .section__right, .section__left {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }

/* line 95, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.sub-section__heading {
  margin-bottom: 28px;
  font-family: 'cardoregular';
  color: #737373;
  font-size: calc(0.92593vw + 15.03704px); }
  @media (max-width: 320px) {
    /* line 95, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .sub-section__heading {
      font-size: 18px; } }
  @media (min-width: 1400px) {
    /* line 95, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .sub-section__heading {
      font-size: 28px; } }

/* line 103, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
img {
  max-width: 100%; }

/* line 107, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.main-centered-text {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  float: none;
  clear: both; }

/* line 115, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.center {
  text-align: center; }

/* line 121, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
hr.divider {
  height: 3px;
  max-width: 85px;
  width: 100%;
  background: #FF9772;
  border: 0;
  margin-bottom: 10px;
  display: inline-block; }
  /* line 130, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
  hr.divider--full-width {
    max-width: none;
    margin: 0;
    height: 1px;
    display: block; }

/* line 138, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
hr.divider--dark {
  background: rgba(255, 255, 255, 0.24);
  border: 0;
  height: 1px;
  margin: 30px auto;
  width: 100%; }

/* line 148, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.arrow {
  position: relative; }
  /* line 151, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
  .arrow--gray {
    background: #EAEAEA;
    position: relative; }
    /* line 155, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .arrow--gray:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(234, 234, 234, 0);
      border-right-color: #EAEAEA;
      border-width: 37px;
      margin-top: -37px; }

/* line 175, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
header a,
footer a {
  text-decoration: none; }

/* line 181, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
figcaption {
  text-align: center;
  opacity: .7;
  margin-top: 20px; }

/* line 188, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.social__icons {
  margin-right: 10px;
  vertical-align: sub;
  max-width: 22px; }

/* line 194, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.no-wrap {
  white-space: nowrap; }

/* line 200, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.hidden-desktop {
  display: none; }
  @media (max-width: 50em) {
    /* line 200, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .hidden-desktop {
      display: initial; } }

/* line 208, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.hidden-mobile {
  display: initial; }
  @media (max-width: 50em) {
    /* line 208, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
    .hidden-mobile {
      display: none !important; } }

/* line 216, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
a {
  color: #FF9772;
  font-family: inherit; }
  /* line 220, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
  a:hover {
    color: #ff713f; }

/* line 225, /Users/jonathan/Sites/hellojony/_scss/base/_global-styles.scss */
.border--bottom {
  border-bottom: 1px solid #919191;
  padding-bottom: 15px; }

/* line 1, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight {
  background: #ffffff; }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .c {
  color: #999988;
  font-style: italic; }

/* Comment */
/* line 3, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .err {
  color: #a61717;
  background-color: #e3d2d2; }

/* Error */
/* line 4, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .k {
  font-weight: bold; }

/* Keyword */
/* line 5, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .o {
  font-weight: bold; }

/* Operator */
/* line 6, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .cm {
  color: #999988;
  font-style: italic; }

/* Comment.Multiline */
/* line 7, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .cp {
  color: #999999;
  font-weight: bold; }

/* Comment.Preproc */
/* line 8, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .c1 {
  color: #999988;
  font-style: italic; }

/* Comment.Single */
/* line 9, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic; }

/* Comment.Special */
/* line 10, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gd {
  color: #000000;
  background-color: #ffdddd; }

/* Generic.Deleted */
/* line 11, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gd .x {
  color: #000000;
  background-color: #ffaaaa; }

/* Generic.Deleted.Specific */
/* line 12, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .ge {
  font-style: italic; }

/* Generic.Emph */
/* line 13, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gr {
  color: #aa0000; }

/* Generic.Error */
/* line 14, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gh {
  color: #999999; }

/* Generic.Heading */
/* line 15, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gi {
  color: #000000;
  background-color: #ddffdd; }

/* Generic.Inserted */
/* line 16, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gi .x {
  color: #000000;
  background-color: #aaffaa; }

/* Generic.Inserted.Specific */
/* line 17, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .go {
  color: #888888; }

/* Generic.Output */
/* line 18, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gp {
  color: #555555; }

/* Generic.Prompt */
/* line 19, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gs {
  font-weight: bold; }

/* Generic.Strong */
/* line 20, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gu {
  color: #aaaaaa; }

/* Generic.Subheading */
/* line 21, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .gt {
  color: #aa0000; }

/* Generic.Traceback */
/* line 22, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .kc {
  font-weight: bold; }

/* Keyword.Constant */
/* line 23, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .kd {
  font-weight: bold; }

/* Keyword.Declaration */
/* line 24, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .kp {
  font-weight: bold; }

/* Keyword.Pseudo */
/* line 25, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .kr {
  font-weight: bold; }

/* Keyword.Reserved */
/* line 26, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .kt {
  color: #445588;
  font-weight: bold; }

/* Keyword.Type */
/* line 27, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .m {
  color: #009999; }

/* Literal.Number */
/* line 28, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .s {
  color: #d14; }

/* Literal.String */
/* line 29, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .na {
  color: #008080; }

/* Name.Attribute */
/* line 30, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .nb {
  color: #0086B3; }

/* Name.Builtin */
/* line 31, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .nc {
  color: #445588;
  font-weight: bold; }

/* Name.Class */
/* line 32, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .no {
  color: #008080; }

/* Name.Constant */
/* line 33, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .ni {
  color: #800080; }

/* Name.Entity */
/* line 34, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .ne {
  color: #990000;
  font-weight: bold; }

/* Name.Exception */
/* line 35, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .nf {
  color: #990000;
  font-weight: bold; }

/* Name.Function */
/* line 36, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .nn {
  color: #555555; }

/* Name.Namespace */
/* line 37, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .nt {
  color: #000080; }

/* Name.Tag */
/* line 38, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .nv {
  color: #008080; }

/* Name.Variable */
/* line 39, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .ow {
  font-weight: bold; }

/* Operator.Word */
/* line 40, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .w {
  color: #bbbbbb; }

/* Text.Whitespace */
/* line 41, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .mf {
  color: #009999; }

/* Literal.Number.Float */
/* line 42, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .mh {
  color: #009999; }

/* Literal.Number.Hex */
/* line 43, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .mi {
  color: #009999; }

/* Literal.Number.Integer */
/* line 44, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .mo {
  color: #009999; }

/* Literal.Number.Oct */
/* line 45, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .sb {
  color: #d14; }

/* Literal.String.Backtick */
/* line 46, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .sc {
  color: #d14; }

/* Literal.String.Char */
/* line 47, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .sd {
  color: #d14; }

/* Literal.String.Doc */
/* line 48, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .s2 {
  color: #d14; }

/* Literal.String.Double */
/* line 49, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .se {
  color: #d14; }

/* Literal.String.Escape */
/* line 50, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .sh {
  color: #d14; }

/* Literal.String.Heredoc */
/* line 51, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .si {
  color: #d14; }

/* Literal.String.Interpol */
/* line 52, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .sx {
  color: #d14; }

/* Literal.String.Other */
/* line 53, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .sr {
  color: #009926; }

/* Literal.String.Regex */
/* line 54, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .s1 {
  color: #d14; }

/* Literal.String.Single */
/* line 55, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .ss {
  color: #990073; }

/* Literal.String.Symbol */
/* line 56, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .bp {
  color: #999999; }

/* Name.Builtin.Pseudo */
/* line 57, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .vc {
  color: #008080; }

/* Name.Variable.Class */
/* line 58, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .vg {
  color: #008080; }

/* Name.Variable.Global */
/* line 59, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .vi {
  color: #008080; }

/* Name.Variable.Instance */
/* line 60, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
.highlight .il {
  color: #009999; }

/* Literal.Number.Integer.Long */
/* line 64, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
pre {
  font-size: 18px;
  border: 0;
  border-radius: 0 0 3px 3px;
  background-color: rgba(51, 59, 63, 0.93);
  padding-top: 20px;
  padding-bottom: 10px;
  color: #fff; }

/* line 75, /Users/jonathan/Sites/hellojony/_scss/base/_syntax.scss */
code {
  padding: 0; }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
.home-banner {
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: 34%; }
  @media (min-width: 50.0625em) {
    /* line 2, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
    .home-banner {
      background-position: 80% center;
      background-size: auto; } }
  /* line 16, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
  .home-banner__text {
    float: left;
    width: 70.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%; }
    @media (min-width: 80.0625em) {
      /* line 16, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
      .home-banner__text {
        width: 54.82143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 16, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
      .home-banner__text {
        width: 56.77083%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 16, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
      .home-banner__text {
        width: 60%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 20, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
  .home-banner__img {
    float: left;
    width: 20.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%;
    min-height: 250px;
    padding-top: 40px; }
    @media (min-width: 80.0625em) {
      /* line 20, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
      .home-banner__img {
        width: 42.32143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 20, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
      .home-banner__img {
        width: 40.10417%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 20, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
      .home-banner__img {
        width: 35%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 26, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
  .home-banner__heading {
    font-family: "Arial";
    font-weight: bold;
    font-size: calc(4.62963vw + 5.18519px);
    padding-top: 85px;
    text-transform: uppercase;
    line-height: 1;
    color: #646464; }
    @media (max-width: 320px) {
      /* line 26, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
      .home-banner__heading {
        font-size: 20px; } }
    @media (min-width: 1400px) {
      /* line 26, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
      .home-banner__heading {
        font-size: 70px; } }
  /* line 36, /Users/jonathan/Sites/hellojony/_scss/includes/_home-top-banner.scss */
  .home-banner__subheading {
    color: #797979;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Arial";
    align-self: flex-end; }

/* line 1, /Users/jonathan/Sites/hellojony/_scss/components/_top-banner.scss */
.top-banner {
  background-color: #EFEFED;
  min-height: 50vh;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: calc(2.31481vw + 67.59259px); }
  @media (max-width: 320px) {
    /* line 1, /Users/jonathan/Sites/hellojony/_scss/components/_top-banner.scss */
    .top-banner {
      margin-bottom: 75px; } }
  @media (min-width: 1400px) {
    /* line 1, /Users/jonathan/Sites/hellojony/_scss/components/_top-banner.scss */
    .top-banner {
      margin-bottom: 100px; } }
  /* line 15, /Users/jonathan/Sites/hellojony/_scss/components/_top-banner.scss */
  .top-banner__heading {
    font-family: "Arial";
    font-weight: bold;
    font-size: calc(4.62963vw + 5.18519px);
    padding-top: 50px;
    text-transform: uppercase;
    line-height: 1; }
    @media (max-width: 320px) {
      /* line 15, /Users/jonathan/Sites/hellojony/_scss/components/_top-banner.scss */
      .top-banner__heading {
        font-size: 20px; } }
    @media (min-width: 1400px) {
      /* line 15, /Users/jonathan/Sites/hellojony/_scss/components/_top-banner.scss */
      .top-banner__heading {
        font-size: 70px; } }
  /* line 24, /Users/jonathan/Sites/hellojony/_scss/components/_top-banner.scss */
  .top-banner__subheading {
    color: #fff;
    font-size: 18px;
    font-family: "Avenir Next";
    align-self: flex-end; }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
.post-list {
  list-style: none;
  clear: both;
  max-width: 1040px; }
  /* line 7, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list__item {
    overflow: hidden;
    margin-bottom: 50px; }
    /* line 11, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
    .post-list__item:hover {
      opacity: .7; }
  /* line 16, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list__img {
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%; }
    @media (min-width: 80.0625em) {
      /* line 16, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list__img {
        width: 29.82143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 16, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list__img {
        width: 23.4375%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 16, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list__img {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 21, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list__text {
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%;
    text-align: left;
    font-family: "Avenir Next";
    padding-top: 40px; }
    @media (min-width: 80.0625em) {
      /* line 21, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list__text {
        width: 67.32143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 21, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list__text {
        width: 73.4375%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 21, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list__text {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 28, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list__heading {
    color: #FF9772;
    font-size: calc(0.55556vw + 16.22222px);
    font-family: "Avenir Next";
    margin-bottom: 0; }
    @media (max-width: 320px) {
      /* line 28, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list__heading {
        font-size: 18px; } }
    @media (min-width: 1400px) {
      /* line 28, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list__heading {
        font-size: 24px; } }
  /* line 35, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list__meta {
    margin-bottom: 10px;
    font-family: "Avenir Next";
    color: #8c8c8c; }
  /* line 41, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list__excerpt {
    font-family: "Avenir Next"; }

/* line 47, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
.post-link {
  text-decoration: none; }

/* line 52, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
.post-list--stacked.post-list {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  clear: both;
  list-style: none; }
  @media (min-width: 50.0625em) {
    /* line 52, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
    .post-list--stacked.post-list {
      flex-flow: row; } }

/* line 66, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
.post-list--stacked .post-list__img, .post-list--stacked .post-list__text, .post-list--stacked .post-list__heading, .post-list--stacked .post-list__meta {
  width: 100%;
  float: none; }

@media (min-width: 30.0625em) {
  /* line 75, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list--stacked .post-list__img {
    width: 35%;
    float: left; }
  /* line 80, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list--stacked .post-list__text {
    width: 60%;
    float: left; } }

@media (min-width: 50.0625em) {
  /* line 87, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
  .post-list--stacked .post-list__img, .post-list--stacked .post-list__text {
    width: 100%;
    float: none; } }

/* line 95, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
.post-list--stacked .post-list__item {
  width: 100%;
  margin-bottom: 5em; }
  @media (min-width: 50.0625em) {
    /* line 95, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
    .post-list--stacked .post-list__item {
      max-width: 325px;
      margin-right: 35px;
      padding: 15px;
      border-radius: 3px; }
      /* line 102, /Users/jonathan/Sites/hellojony/_scss/components/_post-list.scss */
      .post-list--stacked .post-list__item:last-of-type {
        margin-right: 0; } }

/* line 1, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
.portfolio-list {
  list-style: none; }
  /* line 12, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
  .portfolio-list__item {
    margin-bottom: 11em;
    position: relative;
    min-height: 330px; }
    /* line 20, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
    .portfolio-list__item:nth-of-type(2) a {
      flex-direction: row-reverse; }
    /* line 24, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
    .portfolio-list__item:nth-of-type(2) .portfolio-list__text {
      padding-right: 0;
      padding-left: 50px; }
    /* line 29, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
    .portfolio-list__item:nth-of-type(2) .expand {
      right: 0;
      left: inherit;
      background: rgba(213, 226, 234, 0.65); }
    /* line 39, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
    .portfolio-list__item:nth-of-type(3) .expand {
      background: rgba(213, 226, 234, 0.4); }
  /* line 47, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
  .portfolio-list__img {
    min-width: 50%; }
    /* line 50, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
    .portfolio-list__img img {
      box-shadow: 1px 1px 9px rgba(105, 105, 105, 0.23); }
  /* line 55, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
  .portfolio-list__link {
    text-decoration: none;
    color: #fff;
    display: flex; }
    @media (max-width: 50em) {
      /* line 55, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
      .portfolio-list__link {
        display: block; } }
  /* line 65, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
  .portfolio-list__text {
    text-align: left;
    padding-right: 50px;
    transition: background .5s; }
    @media (min-width: 50.0625em) {
      /* line 65, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
      .portfolio-list__text {
        margin-top: 60px; } }
    /* line 74, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
    .portfolio-list__text:hover {
      color: #fff; }
  /* line 79, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
  .portfolio-list__hr {
    max-width: 34%;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #646464;
    border-bottom: 0; }
  /* line 87, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
  .portfolio-list__location {
    font-family: "avenirnext-bold";
    font-size: 16px;
    color: #646464; }
  /* line 93, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
  .portfolio-list__heading {
    font-family: "avenirnext-demibold";
    font-size: 28px;
    font-size: calc(0.92593vw + 15.03704px);
    margin-bottom: 5px;
    color: #646464;
    text-transform: uppercase; }
    @media (max-width: 320px) {
      /* line 93, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
      .portfolio-list__heading {
        font-size: 18px; } }
    @media (min-width: 1400px) {
      /* line 93, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
      .portfolio-list__heading {
        font-size: 28px; } }
  /* line 102, /Users/jonathan/Sites/hellojony/_scss/components/_portfolio-list.scss */
  .portfolio-list__categories {
    color: #fff;
    font-family: "Avenir Next";
    font-size: 14px;
    margin-bottom: 0; }

/* line 1, /Users/jonathan/Sites/hellojony/_scss/components/_contact-form.scss */
.contact-form {
  padding-top: 125px;
  padding-bottom: 35px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px; }
  /* line 10, /Users/jonathan/Sites/hellojony/_scss/components/_contact-form.scss */
  .contact-form label {
    color: #fff;
    color: #464646;
    font-size: 18px;
    padding-bottom: 52px;
    margin-top: 35px;
    font-family: "Avenir Next";
    font-weight: normal; }
  /* line 20, /Users/jonathan/Sites/hellojony/_scss/components/_contact-form.scss */
  .contact-form input, .contact-form textarea {
    font-size: 18px !important;
    text-align: left;
    font-family: "avenirnext-medium" !important;
    min-height: 35px;
    margin-top: 5px !important;
    background: transparent !important;
    border-width: 1px;
    border-color: #d2d2d2 !important; }
    /* line 33, /Users/jonathan/Sites/hellojony/_scss/components/_contact-form.scss */
    .contact-form input:focus, .contact-form textarea:focus {
      border-bottom: 1px solid #FF9772 !important;
      outline: 0; }

/* line 41, /Users/jonathan/Sites/hellojony/_scss/components/_contact-form.scss */
.cognito a {
  visibility: hidden; }

/* line 45, /Users/jonathan/Sites/hellojony/_scss/components/_contact-form.scss */
.cognito .c-field.c-required .c-label:after {
  color: #FF9772; }

/* line 49, /Users/jonathan/Sites/hellojony/_scss/components/_contact-form.scss */
.cognito .c-span-24 .c-span-24 {
  margin-top: 25px; }

/* line 53, /Users/jonathan/Sites/hellojony/_scss/components/_contact-form.scss */
#c-submit-button {
  border: 0; }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/components/_sidebar.scss */
.sidebar {
  float: left;
  width: 95.83333%;
  margin-right: 2.08333%;
  margin-left: 2.08333%; }
  @media (min-width: 80.0625em) {
    /* line 2, /Users/jonathan/Sites/hellojony/_scss/components/_sidebar.scss */
    .sidebar {
      width: 29.82143%;
      margin-right: 0.71429%;
      margin-left: 0.71429%; } }
  @media (min-width: 50.0625em) and (max-width: 80em) {
    /* line 2, /Users/jonathan/Sites/hellojony/_scss/components/_sidebar.scss */
    .sidebar {
      width: 31.77083%;
      margin-right: 0.78125%;
      margin-left: 0.78125%; } }
  @media (min-width: 30.0625em) and (max-width: 50em) {
    /* line 2, /Users/jonathan/Sites/hellojony/_scss/components/_sidebar.scss */
    .sidebar {
      width: 97.5%;
      margin-right: 1.25%;
      margin-left: 1.25%; } }
  /* line 5, /Users/jonathan/Sites/hellojony/_scss/components/_sidebar.scss */
  .sidebar ul {
    list-style: none; }
  /* line 9, /Users/jonathan/Sites/hellojony/_scss/components/_sidebar.scss */
  .sidebar__heading {
    text-align: right;
    font-family: "Avenir Next";
    font-size: calc(0.55556vw + 26.22222px); }
    @media (max-width: 320px) {
      /* line 9, /Users/jonathan/Sites/hellojony/_scss/components/_sidebar.scss */
      .sidebar__heading {
        font-size: 28px; } }
    @media (min-width: 1400px) {
      /* line 9, /Users/jonathan/Sites/hellojony/_scss/components/_sidebar.scss */
      .sidebar__heading {
        font-size: 34px; } }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.site-header {
  padding-top: 5px;
  padding-bottom: 3px;
  height: 45px;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 2; }
  @media (min-width: 50.0625em) {
    /* line 2, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
    .site-header {
      padding-top: 10px;
      height: 65px; } }

/* line 17, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.page {
  padding-top: 45px; }
  @media (min-width: 50.0625em) {
    /* line 17, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
    .page {
      padding-top: 65px; } }

/* line 25, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.logo {
  float: left;
  padding-top: 25px; }
  /* line 29, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
  .logo object, .logo img {
    max-width: 50px; }
  /* line 37, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
  .logo a {
    font-family: "Avenir Next";
    color: #FF9772;
    font-size: 14px;
    text-transform: uppercase; }

/* line 47, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.top-menu {
  padding-top: 22px;
  float: right; }
  /* line 51, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
  .top-menu li {
    list-style: none;
    margin-bottom: 15px;
    display: block;
    float: none;
    opacity: 0;
    padding-bottom: 15px;
    padding-top: 15px; }
  /* line 61, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
  .top-menu__btn {
    margin-top: 30px; }
    @media (min-width: 50.0625em) {
      /* line 61, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
      .top-menu__btn {
        display: none !important; } }
  @media (min-width: 50.0625em) {
    /* line 70, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
    .top-menu li {
      float: left;
      margin-right: 20px;
      list-style: none;
      margin-bottom: 0;
      opacity: 1;
      padding-bottom: 0;
      padding-top: 0; } }
  @media (max-width: 50em) {
    /* line 47, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
    .top-menu {
      padding-top: 120px;
      padding-top: 10vh;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #f7f7f7;
      overflow: hidden;
      width: 100%;
      z-index: 101;
      height: 100vh;
      width: 100%;
      overflow-y: auto;
      text-align: center;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
      will-change: transform;
      -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
      transition: -webkit-transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0, 0.175, 1); }
      /* line 107, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
      .top-menu:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.1;
        background-image: url(img/logos/white-abc-logo.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 80%; } }

/* line 128, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open .top-menu {
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

/* line 133, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open .logo {
  position: absolute;
  z-index: 111; }

/* line 138, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open li {
  -webkit-animation-name: b;
  animation-name: b;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

/* line 151, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open li:nth-child(1) {
  -webkit-animation-delay: .2125s;
  animation-delay: .2125s; }

/* line 156, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open li:nth-child(2) {
  -webkit-animation-delay: .275s;
  animation-delay: .275s; }

/* line 161, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open li:nth-child(3) {
  -webkit-animation-delay: .3375s;
  animation-delay: .3375s; }

/* line 166, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open li:nth-child(4) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s; }

/* line 173, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
button.nav-toggle {
  color: #fff;
  font-size: 0.75rem;
  line-height: 1;
  background: transparent;
  border: 0;
  box-shadow: none;
  display: inline;
  float: right;
  position: relative;
  z-index: 102;
  outline: 0;
  margin-top: 5px; }
  @media (min-width: 50.0625em) {
    /* line 173, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
    button.nav-toggle {
      display: none; } }

/* line 192, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.nav-toggle {
  cursor: pointer;
  padding: 10px 35px 16px 0px; }

/* line 197, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  cursor: pointer;
  height: 2px;
  width: 28px;
  background: #fff;
  position: absolute;
  display: block;
  content: '';
  border-radius: 5px; }

/* line 210, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.nav-toggle span:before {
  top: -8px; }

/* line 214, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.nav-toggle span:after {
  bottom: -8px; }

/* line 220, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open .nav-toggle span {
  background-color: transparent; }

/* line 223, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open .nav-toggle span:before, .open .nav-toggle span:after {
  top: 0; }

/* line 226, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open .nav-toggle span:before {
  transform: rotate(45deg); }

/* line 229, /Users/jonathan/Sites/hellojony/_scss/includes/_header.scss */
.open .nav-toggle span:after {
  transform: rotate(-45deg); }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
.footer {
  background-color: #2C2E31;
  padding-top: 80px;
  padding-bottom: 70px;
  width: 100%;
  bottom: 0; }
  @media (max-width: 50em) {
    /* line 2, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
    .footer {
      text-align: center; } }
  /* line 14, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .footer__heading {
    margin-bottom: 12px;
    color: #fff;
    font-family: "avenirnext-medium"; }
  /* line 20, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .footer__text, .footer__text-link {
    font-family: "ralewaylight";
    margin-bottom: 3px;
    color: #fff; }
  /* line 28, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .footer a {
    color: #fff;
    font-family: "ralewaylight"; }
    /* line 32, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
    .footer a:hover {
      color: #FF9772; }
  /* line 37, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .footer p {
    color: #fff;
    font-size: 15px; }
  /* line 42, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .footer__column {
    margin-bottom: 50px; }
  /* line 46, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .footer__column--1 {
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%; }
    @media (min-width: 80.0625em) {
      /* line 46, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--1 {
        width: 23.57143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 46, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--1 {
        width: 31.77083%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 46, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--1 {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 50, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .footer__column--2 {
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%; }
    @media (min-width: 80.0625em) {
      /* line 50, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--2 {
        width: 23.57143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 50, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--2 {
        width: 31.77083%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 50, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--2 {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 54, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .footer__column--3 {
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%; }
    @media (min-width: 80.0625em) {
      /* line 54, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--3 {
        width: 48.57143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 54, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--3 {
        width: 31.77083%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 54, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
      .footer__column--3 {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }

/* line 62, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
.copyright {
  clear: both;
  margin-top: 50px; }
  /* line 67, /Users/jonathan/Sites/hellojony/_scss/includes/_footer.scss */
  .copyright p {
    font-family: "ralewaylight";
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px; }

/* line 2, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
.idea {
  overflow: hidden; }
  /* line 5, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .idea__left {
    margin-top: 1px;
    min-height: 600px;
    position: relative;
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%; }
    @media (min-width: 80.0625em) {
      /* line 5, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .idea__left {
        width: 42.32143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 5, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .idea__left {
        width: 40.10417%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 5, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .idea__left {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 12, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .idea__right {
    padding-top: 93px;
    max-width: 650px;
    float: left; }
  /* line 18, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .idea__heading {
    text-transform: none;
    font-size: 24px;
    line-height: 1.3;
    font-family: avenirnext-medium;
    color: #646464; }
    @media (min-width: 50.0625em) {
      /* line 18, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .idea__heading {
        font-size: 36px; } }
  /* line 29, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .idea object,
  .idea img {
    position: absolute; }

/* line 36, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
.iphone-image {
  right: 70px;
  max-width: 250px; }

/* line 41, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
.laptop-image {
  bottom: 170px;
  max-width: 488px;
  right: 175px; }

/* line 47, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
.brain {
  padding-top: 115px;
  padding-bottom: 115px;
  background: #f9f9f9;
  margin-bottom: 75px; }

/* line 54, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
.mountain {
  margin-bottom: -183px; }
  /* line 57, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .mountain__img {
    margin-left: -4em;
    max-width: 100%; }
  /* line 62, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .mountain__left {
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%; }
    @media (min-width: 80.0625em) {
      /* line 62, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .mountain__left {
        width: 42.32143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 62, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .mountain__left {
        width: 40.10417%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 62, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .mountain__left {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 66, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .mountain__right {
    float: left;
    width: 95.83333%;
    margin-right: 2.08333%;
    margin-left: 2.08333%;
    padding-top: 120px; }
    @media (min-width: 80.0625em) {
      /* line 66, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .mountain__right {
        width: 54.82143%;
        margin-right: 0.71429%;
        margin-left: 0.71429%; } }
    @media (min-width: 50.0625em) and (max-width: 80em) {
      /* line 66, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .mountain__right {
        width: 56.77083%;
        margin-right: 0.78125%;
        margin-left: 0.78125%; } }
    @media (min-width: 30.0625em) and (max-width: 50em) {
      /* line 66, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .mountain__right {
        width: 97.5%;
        margin-right: 1.25%;
        margin-left: 1.25%; } }
  /* line 71, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .mountain__heading {
    margin-bottom: 25px; }

/* line 76, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
.ending-section {
  background: #CBF0F0;
  color: #fff;
  margin-bottom: 0; }
  /* line 81, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .ending-section__heading {
    padding-top: 200px;
    color: #fff;
    font-family: "Arial";
    font-weight: bold;
    font-size: calc(3.24074vw + 9.62963px); }
    @media (max-width: 320px) {
      /* line 81, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .ending-section__heading {
        font-size: 20px; } }
    @media (min-width: 1400px) {
      /* line 81, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
      .ending-section__heading {
        font-size: 55px; } }

/* line 92, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
.expand {
  background: #D5E2EA;
  min-height: 400px;
  min-width: 20%;
  position: absolute;
  will-change: width;
  z-index: -1;
  max-height: 675px;
  top: -75px;
  left: 0; }
  /* line 107, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
  .expand__right {
    right: 0; }

/* line 112, /Users/jonathan/Sites/hellojony/_scss/pages/_home.scss */
.out-expand {
  position: relative; }

/* line 3, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post__banner {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  margin-bottom: 0;
  min-height: auto;
  text-align: center; }

/* line 12, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post__content {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto; }

/* line 18, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post h1 {
  font-size: calc(0.83333vw + 25.33333px);
  color: #686564;
  font-family: "avenirnext-medium";
  text-align: center;
  margin-bottom: 75px; }
  @media (max-width: 320px) {
    /* line 18, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
    .post h1 {
      font-size: 28px; } }
  @media (min-width: 1400px) {
    /* line 18, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
    .post h1 {
      font-size: 37px; } }

/* line 26, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post p, .post li {
  font-size: 18px;
  color: #3B3837;
  font-family: "Avenir Next";
  line-height: 1.9; }

/* line 33, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post p strong {
  margin-top: 75px;
  display: block; }

/* line 38, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post figure {
  padding-bottom: 35px; }

/* line 42, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post li:before {
  color: #FF9772; }

/* line 46, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post li {
  margin-bottom: 25px; }

/* line 50, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post ol, .post ul {
  padding-left: 20px;
  background: #cbf0f0;
  padding: 25px 40px;
  border-radius: 5px;
  margin-bottom: 50px;
  margin-top: 30px; }

/* line 59, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post__divider {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 75px;
  margin-bottom: 75px;
  border: 1px solid #bdbdbd;
  border-bottom: 0; }

/* line 71, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.post-wrapper {
  width: 100%;
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

/* line 80, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
.editor {
  box-shadow: 0px 1px 8px #bebebe;
  margin-top: 20px;
  margin-bottom: 50px;
  max-width: 900px; }
  /* line 86, /Users/jonathan/Sites/hellojony/_scss/pages/_posts.scss */
  .editor__title {
    background-image: linear-gradient(#fafafa, #F6F6F6);
    margin-bottom: 0;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 2px;
    border-radius: 5px 5px 0 0;
    font-size: 16px; }

/* line 44, stdin */
body {
  background: #fff; }

/* line 50, stdin */
header a,
footer a {
  color: black; }

/* line 55, stdin */
.full-width-panel {
  background: #E0E0E0; }

/* line 60, stdin */
.columns div {
  background: green;
  margin-bottom: 20px;
  padding: 20px;
  float: left;
  width: 95.83333%;
  margin-right: 2.08333%;
  margin-left: 2.08333%; }
  @media (min-width: 80.0625em) {
    /* line 60, stdin */
    .columns div {
      width: 23.57143%;
      margin-right: 0.71429%;
      margin-left: 0.71429%; } }
  @media (min-width: 50.0625em) and (max-width: 80em) {
    /* line 60, stdin */
    .columns div {
      width: 48.4375%;
      margin-right: 0.78125%;
      margin-left: 0.78125%; } }
  @media (min-width: 30.0625em) and (max-width: 50em) {
    /* line 60, stdin */
    .columns div {
      width: 97.5%;
      margin-right: 1.25%;
      margin-left: 1.25%; } }
  @media (min-width: 80.0625em) {
    /* line 60, stdin */
    .columns div {
      background: #CBF0F0; } }
  @media (min-width: 50.0625em) and (max-width: 80em) {
    /* line 60, stdin */
    .columns div {
      background: #E0E0E0; } }
  @media (min-width: 30.0625em) and (max-width: 50em) {
    /* line 60, stdin */
    .columns div {
      background: #E9918B; } }
  @media (min-width: 0) and (max-width: 30em) {
    /* line 60, stdin */
    .columns div {
      background: #CAEFA4; } }

/* line 83, stdin */
.columns-3 div {
  float: left;
  width: 95.83333%;
  margin-right: 2.08333%;
  margin-left: 2.08333%;
  background: #E0E0E0;
  padding: 20px;
  margin-bottom: 25px; }
  @media (min-width: 80.0625em) {
    /* line 83, stdin */
    .columns-3 div {
      width: 31.90268%;
      margin-right: 0.71429%;
      margin-left: 0.71429%; } }
  @media (min-width: 50.0625em) and (max-width: 80em) {
    /* line 83, stdin */
    .columns-3 div {
      width: 31.77083%;
      margin-right: 0.78125%;
      margin-left: 0.78125%; } }
  @media (min-width: 30.0625em) and (max-width: 50em) {
    /* line 83, stdin */
    .columns-3 div {
      width: 47.5%;
      margin-right: 1.25%;
      margin-left: 1.25%; } }

/* line 90, stdin */
.column-16 div {
  background: red;
  float: left;
  width: 20.83333%;
  margin-right: 2.08333%;
  margin-left: 2.08333%;
  height: 50px; }
  @media (min-width: 80.0625em) {
    /* line 90, stdin */
    .column-16 div {
      width: 4.82143%;
      margin-right: 0.71429%;
      margin-left: 0.71429%; } }
  @media (min-width: 50.0625em) and (max-width: 80em) {
    /* line 90, stdin */
    .column-16 div {
      width: 6.77083%;
      margin-right: 0.78125%;
      margin-left: 0.78125%; } }
  @media (min-width: 30.0625em) and (max-width: 50em) {
    /* line 90, stdin */
    .column-16 div {
      width: 10%;
      margin-right: 1.25%;
      margin-left: 1.25%; } }
