//Colors

$accentColor: #FF9772; // FF7442
$secondaryAccent: #2DF8EB;
$secondaryFontColor: #697277;
$headingColors: #646464;
$bodyTextColor: #3B3837;
$backgroundColor: #fff;
$bannerBG: #EFEFED; //#CBF0F0
//Typography

$type__light: 'ralewaylight';
$type__regular: 'Avenir Next';
$type__medium: 'avenirnext-medium';
$type__semibold: 'avenirnext-demibold';
$type__bold: 'avenirnext-bold';

$type__heading: 'Arial';
