
.post {
    &__banner {
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        margin-bottom: 0;
        min-height: auto;
        text-align: center;
    }

    &__content {
        max-width: 1040px;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        @include fp(font-size, 28, 37);
        color: rgb(104, 101, 100);
        font-family: $type__medium;
        text-align: center;
        margin-bottom: 75px;
    }

    p, li {
        font-size: 18px;
        color: $bodyTextColor;
        font-family: $type__regular;
        line-height: 1.9;
    }

    p strong {
        margin-top: 75px;
        display: block;
    }

    figure {
        padding-bottom: 35px;
    }

    li:before {
        color: $accentColor;
    }

    li {
        margin-bottom: 25px;
    }

    ol, ul {
        padding-left: 20px;
        background: #cbf0f0;
        padding: 25px 40px;
        border-radius: 5px;
        margin-bottom: 50px;
        margin-top: 30px;
    }

    &__divider {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 75px;
        margin-bottom: 75px;
        border: 1px solid #bdbdbd;
        border-bottom: 0;
    }

}

.post-wrapper {
    width: 100%;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.editor {
    box-shadow: 0px 1px 8px #bebebe;
    margin-top: 20px;
    margin-bottom: 50px;
    max-width: 900px;

        &__title {
            background-image: linear-gradient(#fafafa, #F6F6F6);
            margin-bottom: 0;
            padding-left: 15px;
            padding-top: 6px;
            padding-bottom: 2px;
            border-radius:  5px 5px 0 0;
            font-size: 16px;
        }
}
