
.btn, #c-submit-button {
    display: inline-block;
    padding: 13px 15px;
    color: #fff !important;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 160px;
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
    box-shadow: none;
    text-shadow: none;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    font-family: $type__regular;

    transition: all .5s ease;

    @include _(greater than mobile) {
		min-width: 180px;
		//font-size: 14px;
    }

    @include _(greater than desktop) {
		min-width: 200px;
    }

    	&:hover {
    		background: darken($accentColor, 10%);
    		box-shadow: none;
            color: #fff;
    	}
}

.btn--dark, #c-submit-button {
    background: #464646;

        &:hover {
            background: darken(#464646, 10%);
        }
}

.btn--accent {
    background: $accentColor;

        &:hover {
            background: darken($accentColor, 10%);
        }
}

.btn--cta {
    background: $secondaryAccent;

    &:hover {
        background: darken($secondaryAccent, 10%);
    }
}

.btn--ghost {
    background: transparent;
    border: 1px solid $accentColor;
    color: $accentColor;

    &:hover {
        background: $accentColor;
        color: #fff;
    }
}

.btn-wrapper {
	@include _(less than laptop) {
		text-align: center;
	}
}
