.out-wrapper {
	max-width: 95%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	zoom: 1;
	float: none;
}

.content-wrapper {
	@include _(16 12 8 4);
	overflow: hidden;
}

.wrapper {
	overflow: hidden;
	zoom: 1;
	float: none;
	margin-right: auto;
	margin-left: auto;
	padding-left: 20px;
	padding-right: 20px;

    @include _(container, clear);


    @include _(greater than tablet) {
  		padding-left:  60px;
  		padding-right: 60px;
  	}

    @include _(greater than laptop) {
  		padding-left:  70px;
  		padding-right: 70px;
    }

}

.accentColor {
	color: $accentColor;
}

.mrg-side-auto {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.anchor{
   display:block;
   height:70px; /* this is the height of your header */
   margin-top:-70px; /* this is again negative value of the height of your header */
   visibility:hidden;
}

.svg-fix {
	position: relative;
	display: inline-block;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}


//layout
.section {
	//margin-top: 115px;
	margin-bottom: 75px;

	@include _(greater than mobile) {
		margin-bottom: 125px;
	}

	@include _(greater than tablet) {
		margin-bottom: 200px;
	}

	&__heading--white {
		color: #d0d0d0;
	}

	&__right, &__left {
		@include _(8 6 8 4);
	}
}

.sub-section {

	&__heading {
		margin-bottom: 28px;
		font-family: 'cardoregular';
		color: #737373;
		@include fp(font-size, 18, 28);
	}
}

img {
  max-width: 100%;
}

.main-centered-text {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	float: none;
	clear: both;
}

.center {
	text-align: center;
}

//Dividers

hr.divider {
	height: 3px;
	max-width: 85px;
	width: 100%;
	background: $accentColor;
	border: 0;
	margin-bottom: 10px;
	display: inline-block;

		&--full-width {
			max-width: none;
			margin: 0;
			height: 1px;
			display: block;
		}
}

hr.divider--dark {
	background: rgba(255, 255, 255, .24);
	border: 0;
	height: 1px;
	margin: 30px auto;
	width: 100%;
}

//Arrows :)

.arrow {
	position: relative;

	&--gray {
		background: #EAEAEA;
		position: relative;

			&:after {
				right: 100%;
				top: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(234, 234, 234, 0);
				border-right-color: #EAEAEA;
				border-width: 37px;
				margin-top: -37px;
			}
	}
}

//Header and Footer
header,
footer {
	a {
		text-decoration: none;
	}
}

//Captions
figcaption {
    text-align: center;
    opacity: .7;
	margin-top: 20px;
}

//Social
.social__icons {
	margin-right: 10px;
	vertical-align: sub;
	max-width: 22px;
}

.no-wrap {
	white-space: nowrap;
}

//Desktop + Mobile hidden class

.hidden-desktop {
	display: none;

	@include _(less than laptop) {
		display: initial;
	}
}

.hidden-mobile {
	display: initial;

	@include _(less than laptop) {
		display: none !important;
	}
}

a {
	color: $accentColor;
	font-family: inherit;

		&:hover {
			color: darken($accentColor, 10%);
		}
}

.border--bottom {
	border-bottom: 1px solid #919191;
  padding-bottom: 15px;
}
