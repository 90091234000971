//Removes left margin from first element and last margin of last element
@mixin columnfix {
	&:nth-of-type(1) {
		margin-left: 0;
	}

	&:last-of-type {
		margin-right: 0;
	}
}

//The fp() “Fluid Property” SCSS mixin
@mixin fp($property, $min, $max, $start: 320, $end: 1400, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}
