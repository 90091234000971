@-webkit-keyframes a {
    to {
        -webkit-transform: rotate(1turn)
    }
}

@keyframes a {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}


@-webkit-keyframes b {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20%);
        transform: translateX(-20%)
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes b {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20%);
        transform: translateX(-20%)
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}
