.top-banner {
    //display: flex;
    background-color: $bannerBG;
    min-height: 50vh;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
    @include fp(margin-bottom, 75, 100);

    // @include _(greater than mobile) {
    //     border-left: 3.125rem solid $backgroundColor;
    //     border-right: 3.125rem solid $backgroundColor;
    // }

    &__heading {
        font-family: $type__heading;
        font-weight: bold;
        @include fp(font-size, 20, 70);
        padding-top: 50px;
        text-transform: uppercase;
        line-height: 1;
    }

    &__subheading {
        color: #fff;
        font-size: 18px;
        font-family: $type__regular;
        align-self: flex-end;
    }
}
