.portfolio-list {
    // display: flex;
    // justify-content: space-between;
    // flex-flow: column;
    // clear: both;
    list-style: none;

    // @include _(greater than 45em) {
    //     flex-flow: row;
    // }

    &__item {
        //width: 100%;
        margin-bottom: 11em;
        position: relative;
        min-height: 330px;
        //display: flex;

        &:nth-of-type(2)  {
          a {
            flex-direction: row-reverse;
          }

          .portfolio-list__text {
            padding-right: 0;
            padding-left: 50px;
          }

          .expand {
            right: 0;
            left: inherit;
            // background: rgba(251, 208, 212, 0.32);
            background: rgba(213, 226, 234, 0.65);
          }

        }

        &:nth-of-type(3) {
          .expand {
            //background: rgba(221, 17, 68, 0.180392);
            background: rgba(213, 226, 234, 0.40);
          }
        }

    }

    &__img {
      min-width: 50%;

        img {
          box-shadow: 1px 1px 9px rgba(105, 105, 105, 0.23);
        }
    }

    &__link {
        text-decoration: none;
        color: #fff;
        display: flex;

        @include _( less than laptop) {
          display: block;
        }
    }

    &__text {
        text-align: left;
        padding-right: 50px;
        transition: background .5s;

        @include _(greater than tablet) {
          margin-top: 60px;
        }

          &:hover {
            color: #fff;
          }
    }

    &__hr {
      max-width: 34%;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #646464;
      border-bottom: 0;
    }

    &__location {
      font-family: $type__bold;
      font-size: 16px;
      color: $headingColors;
    }

    &__heading {
        font-family: $type__semibold;
        font-size: 28px;
        @include fp(font-size, 18, 28);
        margin-bottom: 5px;
        color: #646464;
        text-transform: uppercase;
    }

    &__categories {
        color: #fff;
        font-family: $type__regular;
        //@include fp(font-size, 14, 16);
        font-size: 14px;
        margin-bottom: 0;
    }
}
