
.idea {
  overflow: hidden;

    &__left {
      margin-top: 1px;
      min-height: 600px;
      position: relative;
      @include _(7 5 8 4);
    }

    &__right {
      padding-top: 93px;
      max-width: 650px;
      float: left;
    }

    &__heading {
      text-transform: none;
      font-size: 24px;
      line-height: 1.3;
      font-family: avenirnext-medium;
      color: rgb(100, 100, 100);
        @include _(greater than  tablet) {
  			  font-size: 36px;
  		  }
    }

  object,
  img {
    position: absolute;
  }

}

.iphone-image {
  right: 70px;
  max-width: 250px;
}

.laptop-image {
  bottom: 170px;
  max-width: 488px;
  right: 175px;
}

.brain {
    padding-top: 115px;
    padding-bottom: 115px;
    background: #f9f9f9;
    margin-bottom: 75px;
}

.mountain {
    margin-bottom: -183px;

    &__img {
        margin-left: -4em;
        max-width: 100%;
    }

    &__left {
        @include _(7 5 8 4);
    }

    &__right {
        @include _(9 7 8 4);
        padding-top: 120px;
    }

    &__heading {
        margin-bottom: 25px;
    }
}

.ending-section {
    background: #CBF0F0;
    color: #fff;
    margin-bottom: 0;

        &__heading {
            padding-top: 200px;
            color: #fff;
            font-family: $type__heading;
            font-weight: bold;
            @include fp(font-size, 20, 55);
        }
}


//Expand div styles
.expand {
    background: #D5E2EA;
    min-height: 400px;
    min-width: 20%;
    position: absolute;
    will-change: width;
    z-index: -1;
    max-height: 675px;
    top: -75px;
    left: 0;

    // &__left {
    //     left: 0;
    // }

    &__right {
        right: 0;
    }
}

.out-expand {
    position: relative;
}
