
.sidebar {
    @include _(5 4 8 4);

    ul {
        list-style: none;
    }

    &__heading {
        text-align: right;
        font-family: $type__regular;
        @include fp(font-size, 28,34);
    }
}
