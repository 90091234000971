.contact-form {

    padding-top: 125px;
    padding-bottom: 35px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;

    label {
        color: #fff;
        color: #464646;
        font-size: 18px;
        padding-bottom: 52px;
        margin-top: 35px;
        font-family: $type__regular;
        font-weight: normal;
    }

    input, textarea {
        font-size: 18px !important;
        text-align: left;
        font-family: $type__medium !important;
        min-height: 35px;
        margin-top: 5px !important;
        //background: rgba(255, 255, 255, 0.62) !important;
        background: transparent !important;
        //border-radius: 3px;
        border-width: 1px;
        border-color: #d2d2d2 !important;
        //border-bottom: 1px solid #979797 !important;

        &:focus {
            border-bottom: 1px solid $accentColor !important;
            outline: 0;
        }
    }

}

.cognito a {
    visibility: hidden;
}

.cognito .c-field.c-required .c-label:after {
    color: $accentColor;
}

.cognito .c-span-24 .c-span-24 {
    margin-top: 25px;
}

, #c-submit-button {
    border: 0;
}
