
.home-banner {

    // background-image: url(/img/pixel-monster.png);
    // background-image: url(/img/pixel-monster.svg);
    background-repeat: no-repeat;
    background-position: 100% center;
    background-size: 34%;

    @include _(greater than tablet) {
        background-position: 80% center;
        background-size: auto;
    }


        &__text {
            @include _(9 7 5 3);
        }

        &__img {
            @include _(7 5 3 1);
            min-height: 250px;
            padding-top: 40px;
        }

        &__heading {
            font-family: $type__heading;
            font-weight: bold;
            @include fp(font-size, 20, 70);
            padding-top: 85px;
            text-transform: uppercase;
            line-height: 1;
            color: $headingColors;
        }

        &__subheading {
            color: #797979;
            font-size: 14px;
            text-transform: uppercase;
            font-family: $type__heading;
            align-self: flex-end;
        }
}
