
.site-header {
    padding-top: 5px;
    padding-bottom: 3px;
    height: 45px;
    position: fixed;
    width: 100%;
    background: $backgroundColor;
    z-index: 2;

        @include _(greater than tablet) {
            padding-top: 10px;
            height: 65px;
        }
}

.page {
    padding-top: 45px;

        @include _(greater than tablet) {
            padding-top: 65px;
        }
}

.logo {
    float: left;
    padding-top: 25px;

        object, img {
            max-width: 50px;

            // @include _(greater than tablet) {
            //     font-size: 29px;
            // }
        }

        a {
            font-family: $type__regular;
            color: $accentColor;
            //@include fp(font-size, 16, 32);
            font-size: 14px;
            text-transform: uppercase;
        }

}

.top-menu {
    padding-top: 22px;
    float: right;

        li {
            list-style: none;
            margin-bottom: 15px;
            display: block;
            float: none;
            opacity: 0;
            padding-bottom: 15px;
            padding-top: 15px;
        }

        &__btn {
            margin-top: 30px;

            @include _(greater than tablet) {
                display: none !important;
            }
        }

    @include _(greater than tablet) {
        li {
            float: left;
            margin-right: 20px;
            list-style: none;
            margin-bottom: 0;
            opacity: 1;
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    @include _(less than laptop) {
        padding-top: 120px;
        padding-top: 10vh;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #f7f7f7;
        overflow: hidden;
        width: 100%;
        z-index: 101;
        height: 100vh;
        width: 100%;
        overflow-y: auto;
        text-align: center;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        will-change: transform;
        -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        transition: -webkit-transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);


            &:after {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                opacity: 0.1;
                background-image: url(img/logos/white-abc-logo.svg);
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 80%;
            }
    }

}


.open {
    .top-menu {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }

    .logo {
        position: absolute;
        z-index: 111;
    }

    li {
        -webkit-animation-name: b;
        animation-name: b;
        -webkit-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
        animation-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    li:nth-child(1) {
        -webkit-animation-delay: .2125s;
        animation-delay: .2125s;
    }

    li:nth-child(2) {
        -webkit-animation-delay: .275s;
        animation-delay: .275s;
    }

    li:nth-child(3) {
        -webkit-animation-delay: .3375s;
        animation-delay: .3375s;
    }

    li:nth-child(4) {
        -webkit-animation-delay: .4s;
        animation-delay: .4s;
    }
}

//Hamburger Menu
button.nav-toggle {
    color: #fff;
    font-size: 0.75rem;
    line-height: 1;
    background: transparent;
    border: 0;
    box-shadow: none;
    display: inline;
    float: right;
    position: relative;
    z-index: 102;
    outline: 0;
    margin-top: 5px;

        @include _(greater than tablet) {
            display: none;
        }
}

.nav-toggle {
    cursor: pointer;
    padding: 10px 35px 16px 0px;
}

.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
    -webkit-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    cursor: pointer;
    height: 2px;
    width: 28px;
    background: #fff;
    position: absolute;
    display: block;
    content: '';
    border-radius: 5px;
}

.nav-toggle span:before  {
    top: -8px;
}

.nav-toggle span:after {
    bottom: -8px;
}

//Hamburger animation
.open {
    .nav-toggle span {
      background-color: transparent;
    }
    .nav-toggle span:before, .nav-toggle span:after {
      top: 0;
    }
    .nav-toggle span:before {
      transform: rotate(45deg);
    }
    .nav-toggle span:after {
      transform: rotate(-45deg);
    }
}
