
body {
	color: $bodyTextColor;
    font-family: 'Avenir Next',sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
p,
blockquote,
li,
a,
span,
.wpad_form_group label {
	font-family: sans-serif;
}

h1, h2, h3, h4, h5 {
	font-weight: normal;
}

h1 {
	font-size: 27px;
	line-height: 1.2;
	margin-bottom:  15px;
	font-family: $type__regular;
	color: #fff;
		@include _(greater than  laptop) {
			font-size: 48px;
			line-height: 1.2;
			margin-bottom:  5px;
		}

		@include _(from tablet to laptop ) {
  			font-size: 36px;
  		}
}

h2,
.section__heading {
	font-size: 36px;
	line-height: 1.3;
	font-family: $type__medium;
  margin-bottom: 100px;
  color: #646464;
	text-transform: uppercase;

	&--content {
		font-size: 24px;
		margin-bottom: 20px;
		line-height: 1.3;
    font-family: "avenirnext-medium";
    color: #646464;

		@include _(greater than  tablet) {
			font-size: 36px;
		}
	}
}

h3 {
	font-size: 16px;
	line-height: 1.3;
	margin-bottom:  28px;
    font-family: $type__regular;

		@include _(greater than  tablet) {
				font-size: 28px;
			}
}

h4 {
	font-size: 16px;
	line-height: 1.3;
	margin-bottom: 15px;
	color: $bodyTextColor;

		@include _(greater than  tablet) {
				font-size: 22px;
			}
}

p, label {
	font-size: 14px;
	margin-bottom: 20px;
	line-height: 1.5;
	color: $bodyTextColor;
	font-family: $type__regular;

		@include _(greater than  tablet) {
			font-size: 18px;
		}
}

strong {
	font-family: $type__semibold;
	color: #686564;
}

.section__text {
	font-family: $type__regular;
	@include fp(font-size, 16, 18);
}

.top-menu a {
    font-family: $type__regular;
    color: #7d7d7d;
    font-size: 22px;
    text-transform: uppercase;


    @include _(greater than tablet) {
        font-size: 14px;

        &:hover {
            color: $accentColor;
        }
    }


}

a {
	font-size: 12px;
    font-family: 'montserratlight';

		@include _(greater than  tablet) {
			font-size: 16px;
		}

		@include _(from tablet to laptop )  {
    			font-size: 14px;
    		}
}

blockquote, blockquote p {
	font-size: 15px;
	color: #000;
	line-height: 1.3;

		@include _(greater than  tablet) {
			font-size: 30px;
		}

		@include _(from tablet to laptop )  {
    			font-size: 24px;
    		}
}

//Input placeholder styles

::-webkit-input-placeholder {
   font-family: 'montserratlight';
    font-size: 12px;

    	@include _(greater than  tablet) {
    			font-size: 16px;
    		}

}

:-moz-placeholder { /* Firefox 18- */
   font-family: 'montserratlight';
   font-size: 12px;
   color: #727378;

   	@include _(greater than  tablet) {
   			font-size: 16px;
   		}
}

::-moz-placeholder {  /* Firefox 19+ */
   font-family: 'montserratlight';
   font-size: 12px;
   color: #727378;

   	@include _(greater than  tablet) {
   			font-size: 16px;
   		}
}

:-ms-input-placeholder {
   font-family: 'montserratlight';
   font-size: 12px;
   color: #727378;

   	@include _(greater than  tablet) {
   			font-size: 16px;
   		}
}
