
.post-list {
    list-style: none;
    clear: both;
    max-width: 1040px;

    &__item {
        overflow: hidden;
        margin-bottom: 50px;

            &:hover {
                opacity: .7;
            }
    }

    &__img {
        @include _(5 3 8 4);
    }


    &__text {
        @include _(11 9 8 4);
        text-align: left;
        font-family: $type__regular;
        padding-top: 40px;
    }

    &__heading {
        color: $accentColor;
        @include fp(font-size, 18, 24);
        font-family: $type__regular;
        margin-bottom: 0;
    }

    &__meta {
        margin-bottom: 10px;
        font-family: $type__regular;
        color: #8c8c8c;
    }

    &__excerpt {
        font-family: $type__regular;
    }

}

.post-link {
    text-decoration: none;
}

//Stacked
.post-list--stacked.post-list {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    clear: both;
    list-style: none;

    @include _(greater than tablet) {
        flex-flow: row;
    }

}
.post-list--stacked .post-list {

    &__img,
    &__text,
    &__heading,
    &__meta {
        width: 100%;
        float: none;
    }

    @include _(greater than mobile) {
        &__img {
            width: 35%;
            float: left;
        }

        &__text {
            width: 60%;
            float: left;
        }
    }

    @include _(greater than tablet) {
        &__img, &__text {
            width: 100%;
            float: none;
        }
    }



    &__item {
        width: 100%;
        margin-bottom: 5em;

        @include _(greater than tablet) {
            max-width: 325px;
            margin-right: 35px;
                &:last-of-type {
                    margin-right: 0;
                }

            //background-color: #f9f9f9;
            padding: 15px;
            border-radius: 3px;
        }

    }

}
