
.footer {
	background-color: #2C2E31;
	padding-top: 80px;
	padding-bottom: 70px;
	//position: absolute;
    width: 100%;
	bottom: 0;

	@include _(less than laptop) {
		text-align: center;
	}

	&__heading {
		margin-bottom: 12px;
		color: #fff;
		font-family: $type__medium;
	}

	&__text, &__text-link {
		font-family: $type__light;
		margin-bottom: 3px;
		color: #fff;
	}



	a {
		color: #fff;
		font-family: $type__light;

		&:hover {
			color: $accentColor;
		}
	}

	p {
		color: #fff;
		font-size: 15px;
	}

	&__column {
		margin-bottom: 50px;
	}

	&__column--1 {
		@include _(4 4 8 4);
	}

	&__column--2 {
		@include _(4 4 8 4);
	}

	&__column--3 {
		@include _(8 4 8 4);
	}

}

//Copyright section

.copyright {

	clear: both;
	margin-top: 50px;

	p {
		font-family: $type__light;
		color: rgba(255, 255, 255, .60);
		font-size: 14px;
	}
}
